<template>
  <component :is="layout">
    <router-view />
  </component>
  <div id="aria-live--content" aria-live="polite">{{ sharedStore.ariaLiveText }}</div>
  <div id="intercom-trigger" @click="toggleIntercom"><c-icon name="message_bubble" size="large" /></div>
  <!-- loading dialog displayed when changing solution and reloading the page-->
  <c-modal v-model="showLoadingModal">
    <template v-slot:body>
      <c-typography center>Loading</c-typography>
      <div class="c-text-center c-mt3 c-px4">
        <c-circular-progress color="#1F2929" size="xs" />
      </div> </template
  ></c-modal>
  <div id="toasts">
    <update-available-modal v-if="$store.state.updateExist && !$store.state.needRefreshBar" @on-close="dontRefresh" @on-refresh="refreshApp" />
  </div>
</template>

<script lang="ts">
// External dependencies:
import { mapActions, mapGetters, mapState } from "vuex";
import { mapStores } from "pinia";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import Highcharts from "highcharts";
import highchartsBorderRadius from "highcharts-border-radius";
import patternFillInit from "highcharts/modules/pattern-fill";

// Design system:
import { CIcon, CModal, CTypography, CCircularProgress } from "@enlyft/design-system-v2";

// Internal dependencies:
// Layouts
import fullLayout from "@/layouts/full.vue";
import noSidebarLayout from "@/layouts/no-sidebar.vue";
import emptyLayout from "@/layouts/empty.vue";
import noLayout from "@/layouts/nolayout.vue";
// Shared
import utils from "@/shared/utils";
import { useShared } from "@/stores/shared/store";
import UpdateAvailableModal from "./shared/UpdateAvailableModal.vue";
import { useDiscover } from "@/stores/discover/store";
import { useUser } from "@/stores/user/store";

// "highcharts-border-radius" is needed to allow specific corner radius settings on chart bars - e.g.: 'borderRadiusTopLeft'
highchartsBorderRadius(Highcharts);
// "pattern-fill" needed to enabled pattern fill on chart bars - e.g.: conditional striped bars
patternFillInit(Highcharts);

// @ts-ignore
// import router from "./router";
import { IUser, IUserTeam } from "@/shared/user";

import "@enlyft/design-system-v2/src/components/assets/styles/main.scss";

library.add(fas);

let cookieDomain = "";

switch (process.env.VUE_APP_MODE) {
  case "stage":
    cookieDomain = "black-dust-5551.idatalabs.com";
    break;
  case "testing1":
    cookieDomain = "misty-dream-2423.idatalabs.com";
    break;
  case "testing2":
    cookieDomain = "red-snow-818.idatalabs.com";
    break;
  case "preproduction":
    cookieDomain = "raspy-wood-1197.idatalabs.com";
    break;
  case "production":
    cookieDomain = "enlyft.com";
    break;
  case "prodtest":
    cookieDomain = "app2.enlyft.com";
    break;
  case "qa":
    cookieDomain = "green-wood-9340.idatalabs.com";
    break;
  default:
    cookieDomain = "";
}

export default {
  name: "app",
  components: { noLayout, emptyLayout, noSidebarLayout, fullLayout, CIcon, CModal, CTypography, CCircularProgress, UpdateAvailableModal },
  data(): {
    defaultLayout: string;
    errors: any[];
    allErrors: any[];
    serviceWorker: any;
    dateCreated: number;
    buildNumber: string | null;
    needHardRefresh: boolean;
    isIntercomOpen: boolean;
    showLoadingModal: boolean;
  } {
    return {
      defaultLayout: "empty",
      errors: [],
      allErrors: [],
      serviceWorker: null,
      dateCreated: new Date().getTime(),
      buildNumber: null,
      needHardRefresh: false,
      isIntercomOpen: false,
      showLoadingModal: false,
    };
  },
  created() {
    // while we use persisted state to local storage
    this.$store.dispatch("resetUpdateChecks");
    let vm = this as any;
    const cookies: any = $cookies;

    if (cookies) {
      cookies.config("1d", "/", cookieDomain, true);
      // Tries to get from store first, which is set on sessionStorage, so is tab-wise. Cookies as fallback
      const activeTeamId = this.$store.getters["team/activeTeamId"] ? this.$store.getters["team/activeTeamId"] : cookies ? cookies.get("teamid") : undefined;

      const user = cookies.get("loggedinuser");

      // this token does not have team id, so it is only supposed to be used to get user's teams list
      const jwtAuth = cookies.get("jwt-auth");

      let activeModelId = cookies ? cookies.get("modelId") : undefined;
      // check both lower and camel cases to make sure
      const modelIdFromQuery = new URLSearchParams(window.location.search).get("modelId");
      // if model id from query is different, sets it as active and on cookies
      if (modelIdFromQuery && modelIdFromQuery !== activeModelId) {
        activeModelId = modelIdFromQuery;
        cookies.set("modelId", activeModelId);
      }

      // sets the jwt-auth token (the one used to get a list of teams) got from cookies to local storage if it is not there
      if (!localStorage.getItem("jwt-auth")) {
        utils.setStorage("jwt-auth", jwtAuth);
      }

      setTimeout(() => {
        if (vm.loggedInUser && vm.userTeams && Array.isArray(vm.userTeams) && vm.userTeams.length > 0 && vm.userTeams[0].plan_id && vm.userTeams[0].payment_status) {
          this.setActiveTeamOffering(vm.userTeams, vm.loggedInUser, activeTeamId, activeModelId);
        } else if (jwtAuth && user?.id && user?.api_key) {
          vm.getJWT({ accountId: user.id, apiKey: user.api_key })
            .then(() => {
              vm.getUserTeams({
                accountId: user.id,
                setUser: true,
                apiKey: user.api_key,
              })
                .then((teams: IUserTeam[]) => {
                  vm.setuserTeams(teams);
                  this.setActiveTeamOffering(teams, user, activeTeamId, activeModelId);
                })
                .catch((err: any) => {
                  console.error("error: Error getting teams", err);
                  this.$store.dispatch("setAppNeedLogin", true);
                });
            })
            .catch((err: any) => {
              console.error("error: Error getting subscriptions", err);
              this.$store.dispatch("setAppNeedLogin", true);
            });
        } else if (user && !user.id) {
          console.error("error: User ID not found");
          this.$store.dispatch("setAppNeedLogin", true);
        } else {
          console.info("All session info available for the new session");
          this.$store.dispatch("setAppReady");
        }
      }, 250);
    } else {
      console.info("no cookies?");
      this.$store.dispatch("setAppReady");
    }

    document.addEventListener("swUpdated", (e: any) => {
      this.serviceWorker = e.detail;
    });

    document.addEventListener("swUpdateAvailable", (e: any) => {
      this.serviceWorker = e.detail;
    });

    if (typeof navigator.serviceWorker !== "undefined") {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        // console.info("new version loaded", e);
      });

      navigator.serviceWorker.addEventListener("message", (e: any) => {
        if (e.data && e.data.version && e.data.version !== utils.BUILD_VERSION && e.data.version !== this.buildNumber && e.data.version.length > 10) {
          this.buildNumber = e.data.version;
          const diff: number = new Date().getTime() - this.dateCreated;
          if (diff < 10000) {
            console.info("new version available, auto refresh ", diff / 1000.0, "s");
            window.location.reload();
            return;
          }
          setTimeout(() => {
            this.$store.dispatch("setUpdateExist");
            if (e.data.forceRefresh) {
              this.$store.dispatch("setNeedHardRefresh");
            }
          }, 20000);
        } else {
          this.$store.dispatch("resetUpdateErrorRefresh");
        }
      });
    }

    if ((this as any).loggedInUser) {
      let team = (this as any).activeTeam ? (this as any).activeTeam : null;

      try {
        utils.setUserProperties((this as any).loggedInUser, team);
        utils.startGoogleAnalytics((this as any).loggedInUser, team);
        utils.setMixPanelUserProperties((this as any).loggedInUser, team, this.activeModel, this.userTeams);

        if (this.activeTeam?.account_name && (window as any).mixpanel && (window as any).mixpanel.app) {
          (window as any).mixpanel.app.set_group("Subscription", this.activeTeam?.account_name);
        }

        if (window && window.location && window.location.href) {
          const utmParams = utils.getUTMParams(window.location.href);
          utils.setMixpanelUTMProperties(utmParams, (this as any).loggedInUser.id);
          this.$store.dispatch("setUtmData", utmParams);
        }
      } catch (e) {
        console.error("Error updating user/active team for analytics." + e);
      }
    }
  },
  async mounted() {
    // Force user data (login + users api) sync
    await this.updateUserData();

    // Hack to get Announcekit to comply with MS standards.
    // Announcekit constantly updates the announcekit-frame-wrapper, so we need
    // to listen to changes on document.body. It's horrible,
    // but thankfully the callback is pretty light weight.
    let name = "";
    const observer = new MutationObserver(() => {
      const iframe = document.querySelector(".announcekit-frame-wrapper iframe");
      const iframeName = iframe?.getAttribute("name");
      if (iframe && name !== iframeName) {
        name = iframeName as string;
        iframe.setAttribute("title", "Announcekit widget");
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Hack to get Intercom to comply with MS standards.
    // I created a custom trigger #intercom-trigger so I can control its styles.
    // This trigger will show/hide the intercom messenger when clicked.
    // This trigger will also be hidden for any users that are not
    // using a specific accessibility theme.
    // In order to be able to open/close the tracker, I need to keep track
    // of its state, so these listeners are for that.
    if (window.Intercom) {
      window.Intercom("onShow", () => (this.isIntercomOpen = true));
      window.Intercom("onHide", () => (this.isIntercomOpen = false));
    }
  },
  computed: {
    ...mapStores(useShared, useDiscover, useUser),
    versionNumber(): string | null {
      if (!this.buildNumber) {
        return null;
      }
      return "v1." + this.buildNumber.substr(3, 6);
    },
    layout(): string {
      return (this.$route.meta && (this.$route.meta.layout || this.defaultLayout)) + "-layout";
    },
    ...mapGetters("login", {
      loggedInUser: "getUser",
    }),
    ...mapGetters("team", {
      teamName: "activeTeamName",
      activeTeam: "activeTeam",
      activeTeamId: "activeTeamId",
    }),
    ...mapGetters("model", {
      activeModel: "activeModel",
    }),
    ...mapState("login", {
      userTeams: "teams",
      user: "user",
    }),
  },
  methods: {
    ...mapActions({
      getUserTeams: "login/getUserTeams",
      setActiveTeam: "team/setActiveTeam",
      setuserTeams: "login/setuserTeams",
      getJWT: "login/getJWT",
      getTeamModels: "model/getTeamModels",
      setActiveModel: "model/setActiveModel",
      getTeamFeatures: "team/getTeamFeatures",
      setTeamFeatures: "team/setTeamFeatures",
      setUser: "login/setUser",
      setActiveIntegrations: "integrations/setActiveIntegrations",
    }),
    async updateUserData() {
      // Get user details from new API and set on store
      if (!this.loggedInUser) {
        return;
      }
      const userData = await this.userStore.getUser(this.loggedInUser.id);
      // Do not use data if BE fails
      if (!userData) {
        return;
      }
      await this.setUser({
        ...this.loggedInUser,
        name: userData.name,
        job_title: userData.job_title,
      });
      if (userData.accounts) {
        await this.setuserTeams(userData.accounts);
      }
      const activeTeamNewData = userData.accounts.find((account) => account.account_id === this.activeTeamId);
      if (activeTeamNewData) {
        await this.setActiveTeam(activeTeamNewData);
      }
    },
    dontRefresh() {
      this.$store.dispatch("setNeedRefreshBar");
    },
    refreshApp() {
      this.$store.dispatch("refreshApp");
    },
    setActiveTeamOffering(teams: IUserTeam[], user: IUser, activeTeam?: string, activeModel?: string) {
      let vm = this as any;
      if (teams && Array.isArray(teams)) {
        if (!(this as any).loggedInUser) {
          console.error("Error: User not found in store. Need Login.");
          this.$store.dispatch("setAppNeedLogin", true);
          return;
        }
        vm.setuserTeams(teams);
        if (teams.length === 1) {
          const team = teams[0];
          try {
            vm.setActiveTeamOfferingHelper(team, activeModel);
          } catch (err) {
            console.error("error: Error setting active team/model", err);
            this.$store.dispatch("setAppNeedLogin", true);
          }
          return;
        } else if (activeTeam) {
          const found = teams.find((t) => t.account_id === activeTeam);
          if (found) {
            try {
              vm.setActiveTeamOfferingHelper(found, activeModel);
            } catch (err) {
              console.error("error: Error getting offerings", err);
              this.$store.dispatch("setAppNeedLogin", true);
            }
            return;
          } else {
            this.$store.dispatch("setAppReady");
          }
        } else {
          this.$store.dispatch("setAppReady");
        }
      } else {
        this.$store.dispatch("setAppReady");
      }
    },
    setActiveTeamOfferingHelper(team: any, modelId: string) {
      let vm = this as any;
      if (team.account_id) {
        this.getTeamFeatures(team.account_id).then((data: any) => {
          this.setTeamFeatures(data);
          vm.setActiveTeam(team);
          vm.setActiveModelIfBelongsToTeam(modelId);
          this.$store.dispatch("setAppReady");
        });
      }
    },
    async setActiveModelIfBelongsToTeam(modelId?: string) {
      try {
        const models = await this.getTeamModels();
        if (models && Array.isArray(models)) {
          const urlOrCookieModelId = new URLSearchParams(window.location.search).get("modelId") || this.$cookies.get("modelId");
          if (models.length === 1) {
            // if there is only one model it must be set even if received modelId is different
            const model = models[0];
            // prevent running setActiveModel if is present on store and hasn't change
            if (model._id === urlOrCookieModelId && urlOrCookieModelId === this.activeModel?._id) {
              return;
            }
            this.setActiveModel(model);
          } else if (modelId && modelId != "null") {
            // if there is more than one model, only sets if the modelId received matches one of the team models
            const modelFound = models.find((model) => model._id === modelId);
            // prevent running setActiveModel if is present on store and hasn't change
            if (modelFound._id === urlOrCookieModelId && urlOrCookieModelId === this.activeModel?._id) {
              return;
            }
            if (modelFound) {
              this.setActiveModel(modelFound);
            }
            // if no model is found to set, router will set the first option
          }
        }
      } catch (err: any) {
        console.error("error: teamSelection subscribe in login getTeamModels", err);
        this.$store.dispatch("setAppNeedLogin", true);
      }
    },
    toggleIntercom() {
      if (window.Intercom) {
        window.Intercom(this.isIntercomOpen ? "hide" : "show");
      }
    },
  },
  watch: {
    loggedInUser(val) {
      if (val?.id) {
        try {
          utils.setUserProperties((this as any).val, (this as any).activeTeam);
          utils.setMixPanelUserProperties((this as any).val, (this as any).activeTeam, this.activeModel, this.userTeams);
        } catch (e) {
          console.error("Unknown APM failure " + e);
        }
      }
    },
    activeTeam(val) {
      if (val) {
        try {
          utils.setUserProperties((this as any).loggedInUser, val);
          utils.setMixPanelUserProperties((this as any).loggedInUser, (this as any).val, this.activeModel, this.userTeams);
        } catch (e) {
          console.error("Unknown analytics failure " + e);
        }
      }
    },
    "$route.name"(val) {
      document.body.setAttribute("data-route", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.toast {
  animation: 0.2s ease-out 0s 1 slideInFromBottom;
  opacity: 1;
  margin: 0 auto 10px auto;
  background-color: #fff;
  position: absolute;
  top: 30%;
  left: 30%;
  min-width: 600px;
  padding: 3%;
  color: #4f5f71 !important;
}
.toast-header {
  padding: 5px 10px;
}
.refreshtoast .toast-header {
  color: #333;
}
.refreshbutton {
  margin: 1rem 0;
}
.norefreshbutton {
  float: right;
  text-transform: none;
  font-size: 0.85em;
  margin-bottom: 1rem;
}
#toasts .toast .close {
  cursor: pointer;
}
.update-header {
  border-bottom: none !important;
}
.col-alert {
  margin-top: -15px;
}
@keyframes slideInFromBottom {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateX(0);
  }
}
</style>

<style lang="scss">
:root {
  --container-max-width: 1032px;
  --container-padding-y: 16px;
}

@mixin fontdef($FontPath, $FontVersion, $FontSubFolder: "regular", $FontType: "Regular") {
  src: url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.eot?v=#{$FontVersion}");
  src:
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.eot?#iefix&v=#{$FontVersion}") format("embedded-opentype"),
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.woff?v=#{$FontVersion}") format("woff"),
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.ttf?v=#{$FontVersion}") format("truetype"),
    url("#{$FontPath}/#{$FontSubFolder}/OpenSans-#{$FontType}.svg?v=#{$FontVersion}##{$FontType}") format("svg");
}

@import "assets/styles/custom";

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.app-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.refreshNotice {
  font-size: 0.8rem;
  position: fixed;
  bottom: 3rem;
  background: #fff;
  left: 1rem;
  z-index: 300;
  padding: 0.25rem 1rem 0.5rem 1rem;
  box-shadow: 0px 0px 5px #ccc;
}

.narrowSideBar-header {
  padding-left: var(--sidebar-narrow) !important;
}
/** This div needs to be "visible" for screen readers to read it. */
#aria-live--content {
  height: 1px;
  overflow: hidden;
  width: 1px;
}

#intercom-trigger {
  display: none;
}

@media (prefers-contrast: more) or (prefers-contrast: less) or (prefers-contrast: custom) {
  #intercom-trigger {
    align-items: center;
    background: #fff;
    border: 2px solid #000 !important;
    border-radius: 48px;
    bottom: 20px;
    cursor: pointer;
    display: flex;
    height: 48px;
    justify-content: center;
    position: fixed;
    right: 20px;
    width: 48px;
    z-index: 2147483004;
  }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
